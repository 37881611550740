<template>
    <div class="plc_container">
        <!-- 标题部分 -->
        <div class="plc_title">
            <h3 class="animated bounceInLeft">「 {{ title.text }} 」</h3>
            <p class="common_title_p animated bounceInRight">
                {{ title.describe }}
            </p>
            <div class="title_connect">
                <div class="title_line animated bounceInLeft"></div>
                <small class="animated bounceInRight" @click="immediately()"
                >立即咨询</small
                >
            </div>

        </div>

        <div class="container_architecture"
             v-for="(item, index) in inventoryList"
             :key="item.id">
            <div class="cooperativeUser_title">
                <h2 class="main_catchword_h2"
                    style="margin-top: 90px;font-weight:600;font-size:30px">
                    {{ item.title }}</h2>
            </div>
            <div style=" display: flex; justify-content: center;">
                <img :src="item.superiorityIcon">
            </div>
        </div>

        <div class="homeBottomPart">
            <common-BottomPart
                :emailPhoneInfo="emailPhone"
                :codeInfo="codeQR"
            ></common-BottomPart>
        </div>
        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>

<script>
import commonBottomPart from '../../../../components/bottomPart/bottomPart.vue'
import commonFloatWin from '../../../../components/floatingWindow/floatingWindow.vue'

export default {
    inject: ['reload'],
    components: {commonBottomPart, commonFloatWin},
    mounted() {
        this.initBaseInfoList()
    },
    data() {
        return {
            title: {
                text: '工业物联网',
                describe: '设备智能化，数据可视化，提升产品质量'
            },
            emailPhone: {},
            codeQR: [],

            inventoryList: [
                {
                    id: '1',
                    title: '物联网',
                    superiorityIcon: require('../../../../assets/img/product/application/iot/plc1.png')
                },
            ]
        }
    },
    methods: {
        // 初始化基本信息
        initBaseInfoList() {
            this.productList = this.$store.state.pubInfo.recommendList
            this.emailPhone = this.$store.state.pubInfo.baseInfo
            this.codeQR = this.$store.state.pubInfo.codeList
        },
        // 立即咨询
        immediately() {
            this.reload()
            this.$router.push({name: 'Immediately'})
        }
    }
}
</script>

<style lang="less" scoped>
.container_architecture {
    width: 1915PX;
    margin: 50px auto;

    img {
        width: 1282PX;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
}

.plc_container {
    color: #000;
    position: relative;
    background: #fff;
}

.line_top {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    margin: 30px 0 0 40%;
}

.line_bottom {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    position: relative;
    top: 102px;
    left: 55%;
    // margin-bottom: 3.125rem;
}

.main_catchword_h2 {
    font-size: 30px;
    font-weight: 100;
    text-align: center;
}

.main_catchword_p {
    padding: 40px 0;
    font-size: 15px;
    color: #333;
    //letter-spacing: 2px;
    text-indent: 2rem;
    line-height: 26px;
}

.img {
    width: 100%;
}

.plc_title {
    color: #fff;
    height: 660px;
    background: url('../../../../assets/img/titlebackground/titleB24.jpg') no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    text-align: center;
    position: relative;
}

h3 {
    margin: 0;
    font-size: 60px;
    line-height: 200%;
    font-weight: bold;
    padding-top: 200px;
}

.common_title_p {
    width: 50%;
    line-height: 200%;
    padding: 0 25%;
    font-size: 22px;
    //letter-spacing: 2px;
}

.title_line {
    width: 5%;
    height: 1px;
    background: #2db5a3;
    margin: 8% 0 0 64%;
}

small {
    font-size: 18px;
    position: absolute;
    top: 78%;
    left: 70%;
    cursor: pointer;
    padding: 10px 20px;
    background: #2db5a3;
    border-radius: 4px;
}

.industryBackground {
    height: 200px;
    padding: 50px 100px;

    p {
        font-size: 15px;
        width: 60%;
        margin-left: 20%;
        font-size: 15px;
        color: #333;
        //letter-spacing: 2px;
        text-indent: 2rem;
        line-height: 26px;
    }
}

// 系统主要组成部分
.systemOfFour {
    height: 450px;
    padding: 0 20%;

    ul li {
        float: left;
        width: 18%;
        margin: 7% 0 0 2.8%;
        padding: 20px;
        text-align: center;
        //letter-spacing: 2px;
        img {
            height: 60px;
            display: block;
            margin: 0 0 20px 30%;
        }

        p {
            //letter-spacing: 1px;
            text-indent: 2rem;
            line-height: 20px;
            color: #5e6d82;
        }
    }
}

// 物理实现过程
.WebOfThings {
    height: 1760px;
    padding: 20px 21%;
    background: #f6f8fb;
    position: relative;

    img {
        height: 1500px;
        // text-align: center;
        padding: 0 17%;
    }
}

// 核心功能
.coreFunction {
    height: 500px;
    padding: 30px 20%;
    background: #f6f8fb;

    ul {
        margin-top: 80px;

        li {
            width: 17%;
            height: 260px;
            float: left;
            margin-left: 12px;
            padding: 10px;
            background: #fff;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            border-radius: 5px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

            img {
                width: 60px;
                height: 60px;
                display: block;
                margin-top: 10px;
            }

            strong {
                color: #333;
                font-weight: 400;
                margin-top: 10px;
                font-size: 18px;
                //letter-spacing: 2px;
            }

            p {
                font-size: 14px;
                text-indent: 2rem;
                line-height: 26px;
                //letter-spacing: 1px;
                color: #5e6d82;
            }
        }
    }
}

// 优势
.advantage {
    background: #ebeef5;
    height: 1280px;
    padding: 30px 20%;
    position: relative;

    ul {
        border-radius: 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin-top: 80px;
    }

    ul li {
        width: 90%;
        margin-right: 1%;
        z-index: 99;
        padding: 30px 5%;
        background: #fff;
        display: flex;
        border-bottom: 1px solid #ebeef5;

        .viewLeft,
        .viewRight {
            padding: 0px 5%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 300px;
                height: 200px;
                border-radius: 5px;
                display: block;
            }

            strong {
                color: #333;
                font-weight: 400;
                margin-top: 10px;
                font-size: 22px;
                //letter-spacing: 2px;
            }

            p {
                font-size: 15px;
                text-indent: 2rem;
                line-height: 26px;
                //letter-spacing: 2px;
                color: #5e6d82;
            }
        }
    }
}

/* 小屏幕（平板，大于等于 768px） */
@media (max-width: 768px) {
    .plc_container {
        margin: 100px auto;
        // z-index: 2000px;
    }

    .line_top {
        width: 7rem;
        margin: 0.875rem 0 0 34%;
    }

    .line_bottom {
        width: 7rem;
        top: 13.375rem;
        left: 60%;
    }

    .main_catchword_h2 {
        font-size: 4.875rem !important;
        font-weight: bold;
    }

    //非标自动化设备物联网解决方案
    .plc_title {
        height: 77rem;

        h3 {
            font-size: 100px;
            padding-top: 26.5rem;
        }

        p {
            width: 70%;
            line-height: 200%;
            padding: 12px 16%;
            font-size: 3.375rem;
            //letter-spacing: 1.125rem;
        }

        .title_connect {
            // width: 110rem;
            position: relative;
            display: flex;

            .title_line {
                width: 10rem;
                height: 0.0625rem;
                margin: 12% 0 0 64%;
                display: none;
            }

            small {
                // width: 30rem;
                font-size: 4.125rem;
                border-radius: 1.25rem;
                top: 78%;
                // display: none;

            }
        }

    }

    .main_catchword_p {
        padding: 2.5rem 2rem;
        font-size: 1.9375rem;
        //letter-spacing: 0.125rem;
        text-indent: 6rem;
        line-height: 5.625rem;
    }

    // 行业背景
    .industryBackground {
        height: 52rem;
        padding: 3.125rem 1.25rem;

        p {
            width: 82%;
            margin-left: 9%;
            text-indent: 6rem;
            line-height: 6.625rem;
            //letter-spacing: 0.125rem;
            font-size: 2.8rem;
        }
    }

    // 系统组成部分
    .systemOfFour {
        height: 106.5rem;
        padding: 3.875rem 1%;

        ul li {
            height: 33.25rem;
            width: 47%;
            margin: 6% 1rem 0 0;

            img {
                height: 10.75rem;
                margin: 0 0 1.25rem 36%;
            }

            strong {
                font-weight: bold;
                font-size: 3rem;
            }

            p {
                text-indent: 3rem;
                line-height: 5.25rem;
                font-size: 2.7rem;
            }
        }

    }

    // 物联网实现过程
    .WebOfThings {
        height: 174.5rem;
        padding: 1.875rem 3%;

        img {
            height: 124rem;
            padding: 0.625rem 24%;
        }

        .main_catchword_p {
            font-size: 2.9375rem;
        }
    }

    // 系统架构
    // .framework {
    //   height: 160.25rem;
    //   padding: 4.875rem 1%;
    //   img {
    //     height: 125.75rem;
    //     width: 89%;
    //     margin: 9.75rem 0 0 5%;
    //   }
    // }
    // 核心功能
    .coreFunction {
        height: 134.25rem;
        padding: 1rem 5%;

        ul {
            margin-top: 10rem;

            li {
                width: 30%;
                height: 51.25rem;
                margin-left: 1.8rem;
                padding: 0.625rem;

                img {
                    width: 10.75rem;
                    height: 10.75rem;
                }

                strong {
                    font-weight: bold;
                    font-size: 4.125rem;
                    //letter-spacing: 1.125rem;
                }

                p {
                    font-size: 2.875rem;
                    text-indent: 0rem;
                    line-height: 5.625rem;
                    //letter-spacing: 0.0625rem;
                    padding-left: 1rem;
                }
            }
        }
    }

    // 优势
    .advantage {
        height: 178rem;
        padding: 3.875rem 5%;

        ul {
            height: 170rem;
            width: 100%;
            margin-top: 8rem;

            li {
                width: 100%;
                padding: 0.875rem 0%;
                height: 38rem;

                .viewLeft,
                .viewRight {
                    padding: 0 2%;

                    img {
                        width: 31.75rem;
                        height: 18.5rem;
                        border-radius: -1.6875rem;
                        padding: 9rem 1rem;
                    }

                    strong {
                        font-weight: bold;
                        font-size: 4.375rem;
                    }

                    p {
                        font-size: 2.9375rem;
                        text-indent: 6rem;
                        line-height: 4.625rem;
                    }
                }
            }
        }
    }

    .container_architecture {
        height: 54rem;
        width: 100%;
        margin: 13rem auto;
        .main_catchword_h2{
            margin-top: 17rem !important;
        }
        img{
            height:51rem;
            width:calc(100% - 10rem);
            border-radius: 10px;
            object-fit: contain;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
    }
}
</style>
